import Vue, { createApp } from "vue";
import "./plugins/axios";
import "./registerServiceWorker";
import VueGtag from "vue-gtag-next";

Vue.config.productionTip = false;
const showDevTools = process.env.VUE_APP_SHOW_DEV_TOOLS;

if (showDevTools == "true" || showDevTools == true) {
  Vue.config.devtools = true;
} else {
  Vue.config.devtools = false;
}

import adminMain from "./main-admin.js";
import interviewMain from "./main-interview.js";
import router from "./router";
import { createStore } from 'vuex'
import { createI18n } from 'vue-i18n'
import { auth } from "./store/auth.module";
//import { vfmPlugin } from 'vue-final-modal'
//import MyModal from "./modals/MyModal.vue";
import { configureCompat } from 'vue'

// disable compat for certain features
configureCompat({
  ATTR_FALSE_VALUE: false,

/*  
  WATCH_ARRAY: 'suppress-warning',
  RENDER_FUNCTION: 'suppress-warning',
  INSTANCE_LISTENERS: 'suppress-warning',
  COMPONENT_FUNCTIONAL: 'suppress-warning',
  OPTIONS_BEFORE_DESTROY: 'suppress-warning',
  INSTANCE_SCOPED_SLOTS: 'suppress-warning',
  OPTIONS_DESTROYED: 'suppress-warning',
  INSTANCE_DESTROY: 'suppress-warning',
  COMPONENT_ASYNC: 'suppress-warning',
  PRIVATE_APIS: 'suppress-warning',
  OPTIONS_DATA_MERGE: 'suppress-warning',
  COMPONENT_V_MODEL: 'suppress-warning',
  CUSTOM_DIR: 'suppress-warning',
  INSTANCE_EVENT_EMITTER: 'suppress-warning',
  GLOBAL_PROTOTYPE: 'suppress-warning',
  GLOBAL_EXTEND: 'suppress-warning',
  GLOBAL_MOUNT: 'suppress-warning',
  INSTANCE_ATTRS_CLASS_STYLE: 'suppress-warning',
*/  
});

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
});
let adminConfiguration = adminMain;
let interviewConfiguration = interviewMain;
const isInterviewApp = process.env.VUE_APP_BASE_IS_INTERVIEW_APP;
let gaConfig = {};
if (isInterviewApp == "true" || isInterviewApp == true) {
//  var interviewApp = interviewConfiguration.$mount("#app");
  const interviewApp = createApp(interviewConfiguration).use(router)
  .use(i18n);
  router.isReady().then(() => {
    interviewApp.mount('#app')
  })  
  gaConfig = {
    config: {
      id: process.env.VUE_APP_GA_ANALYTICS,
      params: {
        send_page_view: false
      }
    },
    appName: "Survey Taker",
    pageTrackerScreenviewEnabled: true
  };
  interviewApp.use(VueGtag, gaConfig);
} else {
  gaConfig = {
    config: {
      id: process.env.VUE_APP_GA_ANALYTICS_ADMIN,
      params: {
        send_page_view: false
      }
    },
    appName: "GT Admin",
    pageTrackerScreenviewEnabled: true
  };  
  //var panelApp = adminConfiguration.$mount("#app");
// Create a new store instance.
  const store = createStore({
    modules: {
      auth
    }
  });
  const app = createApp(adminConfiguration).use(store)
  .use(router)
  .use(i18n);
  app.use(VueGtag, gaConfig);
  router.isReady().then(() => {
    app.mount('#app')
  })  
}
