import Vue from "vue";
/* Old Vue2/vue-router3 code
import VueRouter from "vue-router";
Vue.use(VueRouter);
*/
import { createRouter,createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";
import adminRoutes from "./routes/admin.js";
import interviewRoutes from "./routes/interview.js";

const isInterviewApp = process.env.VUE_APP_BASE_IS_INTERVIEW_APP;
let routes =  (isInterviewApp == "true" || isInterviewApp == true) ? interviewRoutes : adminRoutes;


/* Old Vue2/vue-router3 code
const router = new VueRouter({
  mode: "history",
  routes
});
*/
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
});

import {  onAuthRequired } from "../okta";
if (isInterviewApp == "false" || !isInterviewApp) {
  router.beforeEach(onAuthRequired);    
} 
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || "Survey";
  });
});
trackRouter(router); // Google Analytics

export default router;
